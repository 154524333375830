<template>
    <div class="login flex-box">
        <div class="login-title flex-box align-center">
            <h3>啄物鸟后台管理系统</h3>
            <div class="sep"></div>
            <h3>用户登录</h3>
        </div>
        <div class="login-box">
            <div class="login-box-ti">账号密码登录</div>
            <a-form-model
                    class="login-form"
                    ref="loginForm"
                    :model="form"
                    :rules="rules"
                    :label-col="{span: 0}"
                    :wrapper-col="{span: 25}">
                <a-form-model-item label="" prop="username">
                    <a-input size="large" v-model="form.username" placeholder="账号" @keyup.enter="login">
                        <a-icon slot="prefix" type="user"/>
                    </a-input>
                </a-form-model-item>
                <a-form-model-item label="" prop="password">
                    <a-input size="large" type="password" v-model="form.password" placeholder="密码" @keyup.enter="login">
                        <a-icon slot="prefix" type="lock" />
                    </a-input>
                </a-form-model-item>
            </a-form-model>
            <a-button size="large" type="primary" block @click="login">登录</a-button>
            <div class="login-loading" v-show="loading">
                <a-spin tip="正在登陆...">
                    <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                </a-spin>
            </div>
        </div>
    </div>
</template>

<script>
    import {setToken, cleanToken} from "../common/js/storage";

    export default {
        name: "Login",
        data() {
            return {
                form: {},
                rules: {
                    username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                    code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
                },
                loading: false,
                img: null,
                token: null
            }
        },
        created() {
            cleanToken();
            this.$store.commit("setLogin", false);
        },
        methods: {
            login() {
                this.$refs.loginForm.validate(valid => {
                    if (valid) {
                        let form = this.form;
                        this.loading = true;
                        this.$axios({
                            url: '/official/admin-auth',
                            method: "POST",
                            data: {
                                username: form.username,
                                password: form.password
                            }
                        }).then(res => {
                            this.loading = false;
                            let code = res.code;
                            if(code == 0) {
                                this.$message.success('登录成功');
                                setToken(res);
                                let path = this.$route.query.r || '/entity/album'
                                this.$router.push(path);
                                this.$store.commit("setLogin", true);
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(() => {
                            this.loading = false;
                            // this.$message.error("您的账号密码不匹配");
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .login {
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @background-color-light;
    }
    .login-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 64px;
        padding: 0 @padding-lg;
        h3 {
            margin: 0;
            line-height: 1;
        }
    }
    .sep {
        margin: 0 12px;
        width: 1px;
        height: 16px;
        background-color: @text-color-secondary;
    }
    .login-box {
        position: relative;
        margin-top: 200px;
        padding: @padding-lg;
        background-color: @body-background;
        box-shadow: @box-shadow-base;
        border-radius: 4px;
    }
    .login-box-ti {
        font-size: 20px;
        text-align: center;
    }
    .login-form {
        margin-top: 24px;
        width: 300px;
    }
    .code-img-box {
        margin-right: -12px;
        position: relative;
        width: 76px;
        height: 38px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: hidden;
        font-size: 0;
        text-align: center;
    }
    .code-img {
        width: 100%;
        height: 100%;
    }
    .login-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,255,255,.6);
        cursor: pointer;
    }
</style>
